"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCurrentAbility = useCurrentAbility;
var ability_1 = require("@skillrecordings/ability");
var use_user_1 = require("./use-user");
/**
 * Uses next-auth session data to return a viewer's current ability.
 */
function useCurrentAbility() {
    var user = (0, use_user_1.useUser)().user;
    return {
        ability: (0, ability_1.getCurrentAbility)({ purchases: user === null || user === void 0 ? void 0 : user.purchases, role: user === null || user === void 0 ? void 0 : user.role }),
    };
}
